
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';


const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <MultiCodeBlock type='server' mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node",
          "metastring": "#server.js",
          "#server.js": true
        }}>{`// Using Express
const express = require('express');
const app = express();
app.use(express.json());

const { Configuration, PlaidApi, PlaidEnvironments } = require('plaid');

const configuration = new Configuration({
  basePath: PlaidEnvironments[process.env.PLAID_ENV],
  baseOptions: {
    headers: {
      'PLAID-CLIENT-ID': process.env.PLAID_CLIENT_ID,
      'PLAID-SECRET': process.env.PLAID_SECRET,
      'Plaid-Version': '2020-09-14',
    },
  },
});

const client = new PlaidApi(configuration);

app.post('/api/create_link_token', async function (request, response) {
  const configs = {
    user: {
      // This should correspond to a unique id for the current user.
      client_user_id: 'user-id',
    },
    client_name: 'Plaid Test App',
    products: [Products.PaymentInitiation],
    language: 'en',
    webhook: 'https://webhook.sample.com',
    country_codes: [CountryCode.Gb],
    payment_initiation: {
      payment_id: paymentID,
    },
    eu_config: {
      headless: true,
    },
    institution_id: 'USER_SELECTED_INSTITUTION_ID',
  };
  try {
    const createTokenResponse = await client.linkTokenCreate(configs);
    response.json(createTokenResponse.data);
  } catch (error) {
    // handle error
  }
});
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`curl -X POST https://sandbox.plaid.com/link/token/create \\
-H 'Content-Type: application/json' \\
-d '{
  "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
  "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
  "client_name": "Plaid Test App",
  "user": { "client_user_id": "\${UNIQUE_USER_ID}" },
  "products": ["payment_initiation"],
  "country_codes": ["GB"],
  "language": "en",
  "webhook": "https://webhook.sample.com",
  "payment_initiation": { "payment_id": "\${PAYMENT_ID}" },
  "eu_config": {
    "headless": true
  },
  "institution_id": "\${USER_SELECTED_INSTITUTION_ID}",
}'
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`require 'sinatra'
require 'plaid'

configuration = Plaid::Configuration.new
configuration.server_index = Plaid::Configuration::Environment[ENV['PLAID_ENV'] || 'sandbox']
configuration.api_key['PLAID-CLIENT-ID'] =ENV['PLAID_CLIENT_ID']
configuration.api_key['PLAID-SECRET'] = ENV['PLAID_SECRET']
configuration.api_key['Plaid-Version'] = '2020-09-14'

api_client = Plaid::ApiClient.new(
  configuration
)

client = Plaid::PlaidApi.new(api_client)

post '/api/create_link_token' do
  # Create a link_token for the given user
  link_token_create_request = Plaid::LinkTokenCreateRequest.new(
    {
      user: { client_user_id: 'user-id' },
      client_name: 'Plaid Test App',
      products: ['payment_initiation'],
      country_codes: ['GB'],
      language: "en",
      redirect_uri: nil_if_empty_envvar('PLAID_REDIRECT_URI'),
      webhook: 'https://webhook.sample.com',
      payment_initiation: { payment_id: payment_id},
      eu_config: { headless: true },
      institution_id: "USER_SELECTED_INSTITUTION_ID"
    }
  )
  response = client.link_token_create(link_token_create_request)
  content_type :json
  response.to_json
end
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java",
          "metastring": "#Server.java",
          "#Server.java": true
        }}>{`import java.net.*;
import java.io.*;
import com.sun.net.httpserver.*;

import com.plaid.client.ApiClient;
import com.plaid.client.request.PlaidApi;
import com.plaid.client.model.LinkTokenCreateRequest;
import com.plaid.client.model.LinkTokenCreateRequestUser;
import com.plaid.client.model.LinkTokenCreateResponse;

public class PlaidExample {
  // Load these from a secrets manager
  private static final String CLIENT_ID = PLAID_CLIENT_ID;
  private static final String SECRET = PLAID_SECRET;

  public static void main(String[] args) {
    HttpServer server = HttpServer.create(
      new InetSocketAddress("localhost", 8000), 0);
    server.createContext("/create_link_token", new GetLinkToken());
    server.setExecutor(null);
    server.start();
  }

  static class GetLinkToken implements HttpHandler {
    private static PlaidClient plaidClient;

    public void handle(HttpExchange t) throws IOException {
      // Create your Plaid client
      HashMap<String, String> apiKeys = new HashMap<String, String>();
      apiKeys.put("clientId", CLIENT_ID);
      apiKeys.put("secret", SECRET);
      apiKeys.put("plaidVersion", "2020-09-14");
      apiClient = new ApiClient(apiKeys);
      apiClient.setPlaidAdapter(ApiClient.Sandbox);

      plaidClient = apiClient.createService(PlaidApi.class);

      // Simplified psuedo-code for obtaining a user_id
      User userFromDB = db.find(...);
      String clientUserId = userFromDB.id;
      LinkTokenCreateRequestUser user = new LinkTokenCreateRequestUser()
        .clientUserId(clientUserId);

      // Create a link_token for the given user
      LinkTokenCreateRequestPaymentInitiation paymentInitiation = new LinkTokenCreateRequestPaymentInitiation()
      .paymentId(paymentId);

      LinkTokenCreateRequest request = new LinkTokenCreateRequest()
        .user(user)
        .clientName("Plaid Test App")
        .products(['payment_initiation']))
        .countryCodes(['GB']))
        .language("en")
        .redirectUri(redirectUri)
        .webhook("https://sample.webhook.com")
        .paymentInitiation(paymentInitiation);

      Response<LinkTokenCreateResponse> response = plaidClient
        .linkTokenCreate(request)
        .execute();

      // Send the data to the client
      return response.body();
    }
  }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python"
        }}>{`import plaid
from flask import Flask
from flask import render_template
from flask import request
from flask import jsonify

app = Flask(name)

configuration = plaid.Configuration(
  host=plaid.Environment.Sandbox,
  api_key={
    'clientId': PLAID_CLIENT_ID,
    'secret': PLAID_SECRET,
    'plaidVersion': '2020-09-14'
  }
)

api_client = plaid.ApiClient(configuration)
client = plaid_api.PlaidApi(api_client)

@app.route("/create_link_token", methods=['POST'])
def create_link_token(): # Get the client_user_id by searching for the current user
user = User.find(...)
client_user_id = user.id

    # Create a link_token for the given user
    request = LinkTokenCreateRequest(
            products=[Products('payment_initiation')],
            client_name="Plaid Test App",
            country_codes=[CountryCode('GB')],
            language='en',
            webhook='https://webhook.sample.com',
            payment_initiation=LinkTokenCreateRequestPaymentInitiation(
                payment_id=payment_id
            ),
            user=LinkTokenCreateRequestUser(
                client_user_id=client_user_id
            )
        )
    response = client.link_token_create(request)
    link_token = response['link_token']

    # Send the data to the client
    return jsonify(response.to_dict())

if __name__ == "__main__":
    app.run(port=8000)
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go"
        }}>{`import (
    "context"
    "net/http"
    "os"

    "github.com/gin-gonic/gin"
    "github.com/plaid/plaid-go/plaid"
)


configuration := plaid.NewConfiguration()
configuration.AddDefaultHeader("PLAID-CLIENT-ID", os.Getenv("CLIENT_ID"))
configuration.AddDefaultHeader("PLAID-SECRET", os.Getnev("SECRET"))
configuration.UseEnvironment(plaid.Sandbox)
client := plaid.NewAPIClient(configuration)

func createLinkToken(c *gin.Context) {
  ctx := context.Background()

  // Get the client_user_id by searching for the current user
  user, _ := usermodels.Find(...)
  clientUserId := user.ID.String()

  // Create a link_token for the given user
  paymentInitiationReq := plaid.NewLinkTokenCreateRequestPaymentInitiation("PAYMENT_ID")
  request := plaid.NewLinkTokenCreateRequest("Plaid Test App", "en", []plaid.CountryCode{plaid.COUNTRYCODE_GB}, *plaid.NewLinkTokenCreateRequestUser(clientUserId))
  request.SetWebhook("https://webhook.sample.com")
  request.SetProducts([]plaid.Products{plaid.PRODUCTS_PAYMENT_INITIATION})
  request.SetPaymentInitiation(*paymentInitiationReq)

    resp, _, err := testClient.PlaidApi.LinkTokenCreate(ctx).LinkTokenCreateRequest(*request).Execute()

  // Send the data to the client
  c.JSON(http.StatusOK, gin.H{
    "link_token": resp.GetLinkToken(),
  })
}

func main() {
  r := gin.Default()
  r.POST("/create_link_token", createLinkToken)

  err := r.Run(":8000")
  if err != nil {
    panic("unable to start server")
  }
}
`}</code></pre>
    </MultiCodeBlock>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
