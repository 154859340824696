import Button from 'plaid-threads/Button';
import Image from 'src/components/Image';
import IconButton from 'plaid-threads/IconButton';
import MenuS1 from 'plaid-threads/Icons/MenuS1';
import React, { useState } from 'react';
import cx from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Sidebar from '../Sidebar';
import styles from './index.module.scss';
import { EXCHANGE_DOCS_BASE_URL } from '../constants';

interface Props {
  baseUrl: string;
}

const Navbar: React.FC<Props> = (props: Props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const router = useRouter();
  if (router.events) {
    // router.events can be undefined on initial load
    router.events.on('routeChangeStart', () => setShowSidebar(false));
  }

  return (
    <>
      <nav className={cx(styles.navbar, styles.mainNav)} aria-label='Secondary'>
        <div className={styles.leftColumn}>
          <IconButton
            className={styles.sidebarToggle}
            accessibilityLabel='Open nav'
            icon={<MenuS1 />}
            onClick={() => {
              setShowSidebar(true);
            }}
          />
          <div className={styles.homeLink}>
            <Link href={props.baseUrl} passHref>
              <a
                href={props.baseUrl}
                rel='noopener noreferrer'
                className={styles.logo}
              >
                <Image
                  className={styles.logoImage}
                  src='/assets/img/navbar/logo.svg'
                  alt='Plaid logo'
                  width='80'
                  height='30.47'
                />
                <span className={styles.logoText}>Exchange</span>
              </a>
            </Link>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <Link href='/' passHref>
            <a className={styles.link} href='/'>
              Plaid.com
            </a>
          </Link>
          <Button
            href='https://plaid.com/plaid-exchange/#contactForm'
            target='_blank'
            className='signup-attach-utm'
            secondary
          >
            Get Started
          </Button>
        </div>
      </nav>
      <Sidebar
        mobile
        closed={!showSidebar}
        onClose={() => setShowSidebar(false)}
        className={styles.sidebar}
        baseUrl={EXCHANGE_DOCS_BASE_URL}
      />
      {showSidebar && (
        <div
          className={styles.sidebarBackdrop}
          role='button'
          tabIndex={-1}
          onClick={() => setShowSidebar(false)}
          onKeyDown={(e) => {
            if (e.keyCode === 27) {
              // escape
              setShowSidebar(false);
            }
          }}
        ></div>
      )}
    </>
  );
};

export default Navbar;
