
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

import DocsBeta from 'src/components/docs/DocsBeta/index.tsx';
import AccountGuidance from './AccountGuidance';
import Partner from './Partner';

const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const MultiCodeBlock = makeShortcode("MultiCodeBlock");
const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h5 {...{
      "id": "write-server-side-handler"
    }}>{`Write server-side handler`}</h5>
    <p>{`The Link module handles the entire onboarding flow securely and quickly, but does not actually retrieve account
data for a user. Instead, the Link module returns a `}<inlineCode parentName="p">{`public_token`}</inlineCode>{` and an `}<inlineCode parentName="p">{`accounts`}</inlineCode>{` array, which is a property
on the `}<inlineCode parentName="p">{`metadata`}</inlineCode>{` object, via the `}<inlineCode parentName="p">{`onSuccess`}</inlineCode>{` callback. Exchange this `}<inlineCode parentName="p">{`public_token`}</inlineCode>{` for a Plaid `}<inlineCode parentName="p">{`access_token`}</inlineCode>{`
using the `}<inlineCode parentName="p">{`/item/public_token/exchange`}</inlineCode>{` API endpoint.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`accounts`}</inlineCode>{` array will contain information about bank accounts associated with the credentials entered by the
user, and may contain multiple accounts if the user has more than one bank account at the institution. `}<AccountGuidance partner={props.partner} isPaymentProcessor={props.isPaymentProcessor} mdxType="AccountGuidance" /></p>
    <p>{`Once you have identified the account you will use, you will send the `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` and `}<inlineCode parentName="p">{`account_id`}</inlineCode>{` property of the account to Plaid via the `}<inlineCode parentName="p">{`/processor/token/create`}</inlineCode>{` endpoint in order to create a `}<Partner p={props.partner} mdxType="Partner" />{` `}<inlineCode parentName="p">{`processor_token`}</inlineCode>{`. You'll send this token
to `}<Partner p={props.partner} mdxType="Partner" />{` and they will use it to securely retrieve account details from Plaid.`}</p>
    <p>{`You can create `}<Partner p={props.partner} mdxType="Partner" />{` `}<inlineCode parentName="p">{`processor_tokens`}</inlineCode>{` in both API environments:`}</p>
    <ul>
      <li parentName="ul">{`Sandbox (`}<a parentName="li" {...{
          "href": "https://sandbox.plaid.com"
        }}>{`https://sandbox.plaid.com`}</a>{`): test simulated users`}</li>
      <li parentName="ul">{`Production (`}<a parentName="li" {...{
          "href": "https://production.plaid.com"
        }}>{`https://production.plaid.com`}</a>{`): production environment for when you're ready to go live and
have valid `}<Partner p={props.partner} mdxType="Partner" />{` Production credentials`}</li>
    </ul>
    <MultiCodeBlock type='server' title='Processor token create request' templateProps={{
      clientLibrary: props.clientLibrary
    }} mdxType="MultiCodeBlock">
      <pre><code parentName="pre" {...{
          "className": "language-node"
        }}>{`const {
  Configuration,
  PlaidApi,
  PlaidEnvironments,
  ProcessorTokenCreateRequest,
} = require('plaid');

// Change sandbox to production when you're ready to go live!
const configuration = new Configuration({
  basePath: PlaidEnvironments[process.env.PLAID_ENV],
  baseOptions: {
    headers: {
      'PLAID-CLIENT-ID': process.env.PLAID_CLIENT_ID,
      'PLAID-SECRET': process.env.PLAID_SECRET,
      'Plaid-Version': '2020-09-14',
    },
  },
});

const plaidClient = new PlaidApi(configuration);

try {
  // Exchange the public_token from Plaid Link for an access token.
  const tokenResponse = await plaidClient.itemPublicTokenExchange({
    public_token: publicToken,
  });
  const accessToken = tokenResponse.data.access_token;

  // Create a processor token for a specific account id.
  const request: ProcessorTokenCreateRequest = {
    access_token: accessToken,
    account_id: accountID,
    processor: '{{{clientLibrary}}}',
  };
  const processorTokenResponse = await plaidClient.processorTokenCreate(
    request,
  );
  const processorToken = processorTokenResponse.data.processor_token;
} catch (error) {
  // handle error
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`# Exchange token
curl -X POST https://sandbox.plaid.com/item/public_token/exchange \\
  -H 'Content-Type: application/json' \\
  -d '{
    "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
    "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
    "public_token": "\${PUBLIC_TOKEN}"
  }'

# Create a processor token for a specific account id.
curl -X POST https://sandbox.plaid.com/processor/token/create \\
  -H 'Content-Type: application/json' \\
  -d '{
    "client_id": <ClientQD>"\${PLAID_CLIENT_ID}"</ClientQD>,
    "secret": <SecretQD>"\${PLAID_SECRET}"</SecretQD>,
    "access_token": "\${ACCESS_TOKEN}",
    "account_id": "\${ACCOUNT_ID}",
    "processor": "{{{clientLibrary}}}"
  }'
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`require 'plaid'

# Change sandbox to production when you're ready to go live!
configuration = Plaid::Configuration.new
configuration.server_index = Plaid::Configuration::Environment[ENV['PLAID_ENV'] || 'sandbox']
configuration.api_key['PLAID-CLIENT-ID'] = ENV['PLAID_CLIENT_ID']
configuration.api_key['PLAID-SECRET'] = ENV['PLAID_SECRET']
configuration.api_key['Plaid-Version'] = '2020-09-14'

api_client = Plaid::ApiClient.new(
  configuration
)

client = Plaid::PlaidApi.new(api_client)

# Exchange the public token from Plaid Link for an access token.
item_public_token_exchange_request = Plaid::ItemPublicTokenExchangeRequest.new(
  {
    public_token: public_token
  }
)
exchange_token_response = client.item_public_token_exchange(item_public_token_exchange_request)
access_token = exchange_token_response.access_token

# Create a processor token for a specific account id.
processor_token_create_request = Plaid::ProcessorTokenCreateRequest.new(
  {
    access_token: access_token,
    account_id: account_id,
    processor: "{{{clientLibrary}}}"
  }
)
create_response = client.processor_token_create(processor_token_create_request)
processor_token = create_response.processor_token
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-java"
        }}>{`import com.plaid.client.model.ItemPublicTokenExchangeRequest;
import com.plaid.client.model.ItemPublicTokenExchangeResponse;
import com.plaid.client.model.ProcessorTokenCreateRequest;
import com.plaid.client.model.ProcessorTokenCreateResponse;

// Change sandbox to production when you're ready to go live!
HashMap<String, String> apiKeys = new HashMap<String, String>();
apiKeys.put("clientId", plaidClientId);
apiKeys.put("secret", plaidSecret);
apiKeys.put("plaidVersion", "2020-09-14");
apiClient = new ApiClient(apiKeys);
apiClient.setPlaidAdapter(ApiClient.Sandbox);

plaidClient = apiClient.createService(PlaidApi.class);

// Exchange the public token from Plaid Link for an access token.
ItemPublicTokenExchangeRequest exchangeRequest = new ItemPublicTokenExchangeRequest()
  .publicToken(publicToken);

Response<ItemPublicTokenExchangeResponse> exchangeResponse = client()
  .itemPublicTokenExchange(exchangeRequest)
  .execute();

// Create a processor token for a specific account id.
if (exchangeResponse.isSuccessful()) {
  String accessToken = exchangeResponse.body().getAccessToken();
  ProcessorTokenCreateRequest createRequest = new ProcessorTokenCreateRequest()
    .accessToken(accessToken)
    .processor("{{{clientLibrary}}}")
    .accountId("FooBarAccountId");

  Response<ProcessorTokenCreateResponse> createResponse = client()
    .processorTokenCreate(createRequest)
    .execute();

    if (createResponse.isSuccessful()) {
      String processorToken = createResponse.body().getProcessorToken();
    }
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-python"
        }}>{`import plaid
from plaid.model.item_public_token_exchange_request import ItemPublicTokenExchangeRequest
from plaid.model.processor_token_create_request import ProcessorTokenCreateRequest

# Change sandbox to production when you're ready to go live!
configuration = plaid.Configuration(
    host=plaid.Environment.Sandbox,
    api_key={
        'clientId': PLAID_CLIENT_ID,
        'secret': PLAID_SECRET,
        'plaidVersion': '2020-09-14'
    }
)
api_client = plaid.ApiClient(configuration)
client = plaid_api.PlaidApi(api_client)

# Exchange the public token from Plaid Link for an access token.
exchange_request = ItemPublicTokenExchangeRequest(public_token=public_token)
exchange_token_response = client.item_public_token_exchange(exchange_request)
access_token = exchange_token_response['access_token']

# Create a processor token for a specific account id.
create_request = ProcessorTokenCreateRequest(
    access_token=access_token,
    account_id=account_id,
    processor="{{{clientLibrary}}}"
)
create_response = client.processor_token_create(create_request)
processor_token = create_response['processor_token']
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-go"
        }}>{`import (
  "context"
  "os"

  plaid "github.com/plaid/v3/plaid-go"
)

// create the client
configuration := plaid.NewConfiguration()
configuration.AddDefaultHeader("PLAID-CLIENT-ID", os.Getenv("CLIENT_ID"))
configuration.AddDefaultHeader("PLAID-SECRET", os.Getenv("SECRET"))
configuration.UseEnvironment(plaid.Sandbox)

// exchange the public_token for an access_token
exchangePublicTokenResp, _, err := client.PlaidApi.ItemPublicTokenExchange(ctx).ItemPublicTokenExchangeRequest(
  *plaid.NewItemPublicTokenExchangeRequest("PUBLIC_TOKEN"),
).Execute()
accessToken := exchangePublicTokenResp.GetAccessToken()

// get the account id
accountsResp, _, err := client.PlaidApi.AccountsGet(ctx).AccountsGetRequest(plaid.AccountsGetRequest{
  AccessToken: accessToken,
}).Execute()
accountID := accountsResp.GetAccounts()[0].GetAccountId()

// create a processor token for the specific account id
request := plaid.NewProcessorTokenCreateRequest(accessToken, accountID, "{{{clientLibrary}}}")
stripeTokenResp, _, err := client.PlaidApi.ProcessorTokenCreate(ctx).ProcessorTokenCreateRequest(
  *request,
).Execute()
`}</code></pre>
    </MultiCodeBlock>
    <p>{`For a valid request, the API will return a JSON response similar to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json",
        "metastring": "#Processor token create response",
        "#Processor": true,
        "token": true,
        "create": true,
        "response": true
      }}>{`{
  "processor_token": "processor-sandbox-0asd1-a92nc",
  "request_id": "m8MDnv9okwxFNBV"
}
`}</code></pre>
    <p>{`For possible error codes, see the full listing of Plaid `}<a parentName="p" {...{
        "href": "/docs/errors"
      }}>{`error codes`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

            export const tableOfContents = [
      
];
          
layoutProps.tableOfContents = tableOfContents;
MDXContent.layoutProps = layoutProps;

MDXContent.layout = function (props) { return <DocsBeta {...props} /> };
